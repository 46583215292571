import { createWebHistory, createRouter } from "vue-router";

import Page from "./layout/page.vue";
import Home from "./views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/page",
    component: Page,
    children: [
      {
        path: "aboutUs",
        name: "aboutUs",
        components: {
          desktop: () => import("./views/desktop/AboutUs.vue"),
          mobile: () => import("./views/mobile/AboutUs.vue"),
        },
        meta: {
          title: "關於我們",
          content: "代買幫關於我們",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "freight",
        name: "freight",
        components: {
          desktop: () => import("./views/desktop/Fee.vue"),
          mobile: () => import("./views/mobile/Fee.vue"),
        },
        meta: {
          title: "費用說明",
          content: "代買幫費用說明",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "notice",
        name: "notice",
        components: {
          desktop: () => import("./views/desktop/Notice.vue"),
          mobile: () => import("./views/mobile/Notice.vue"),
        },
        meta: {
          title: "Q&A",
          content: "代買幫Q&A",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "prohibit",
        name: "prohibit",
        components: {
          desktop: () => import("./views/desktop/Prohibit.vue"),
          mobile: () => import("./views/mobile/Prohibit.vue"),
        },
        meta: {
          title: "禁運商品",
          content: "代買幫禁運商品",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "service",
        name: "service",
        components: {
          desktop: () => import("./views/desktop/Service.vue"),
          mobile: () => import("./views/mobile/Service.vue"),
        },
        meta: {
          title: "服務介紹",
          content: "代買幫服務介紹",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "blogList",
        name: "blogList",
        components: {
          desktop: () => import("./views/desktop/BlogList.vue"),
          mobile: () => import("./views/mobile/BlogList.vue"),
        },
        meta: {
          title: "部落格",
          content: "代買幫部落格",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "blogPage/:id-:title",
        name: "blogPage",
        components: {
          desktop: () => import("./views/desktop/BlogPage.vue"),
          mobile: () => import("./views/mobile/BlogPage.vue"),
        },
        meta: {
          title: "部落格分頁",
          content: "代買幫部落格分頁",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "blogTopic",
        name: "blogTopic",
        components: {
          desktop: () => import("./views/desktop/BlogTopic.vue"),
          mobile: () => import("./views/mobile/BlogTopic.vue"),
        },
        meta: {
          title: "部落格類別分頁",
          content: "代買幫部落格類別分頁",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "termsService",
        name: "termsService",
        components: {
          desktop: () => import("./views/desktop/TermsService.vue"),
          mobile: () => import("./views/mobile/TermsService.vue"),
        },
        meta: {
          title: "服務條款",
          content: "代買幫服務條款",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },

      {
        path: "privacyPolicy",
        name: "privacyPolicy",
        components: {
          desktop: () => import("./views/desktop/PrivacyPolicy.vue"),
          mobile: () => import("./views/mobile/PrivacyPolicy.vue"),
        },
        meta: {
          title: "隱私權政策",
          content: "代買幫隱私權政策",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "tableOfContents",
        name: "tableOfContents",
        components: {
          mobile: () => import("./views/mobile/TableOfContents..vue"),
        },
        meta: {
          title: "目錄分頁",
          content: "代買幫目錄分頁",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },

      // redirect for Google Search Console
      {
        path: "blogPage/320971",
        redirect:
          "page/blogPage/320971-%E3%80%90%E6%95%99%E5%AD%B8%E3%80%91%E9%98%BF%E9%87%8C%E5%B7%B4%E5%B7%B4%E5%A6%82%E4%BD%95%E6%89%B9%E8%B2%A8%EF%BC%9F%E9%80%8F%E9%81%8E%E4%BB%A3%E8%B2%B7%E5%B9%AB%EF%BC%8C%E8%BC%95%E9%AC%86%E5%8F%88%E4%BE%BF%E5%88%A9",
      },
      {
        path: "blogPage/2010946",
        redirect:
          "page/blogPage/2010946-%E8%B2%A8%E6%BA%90%EF%BC%8C%E5%BE%9E%E5%93%AA%E4%BE%86%E2%80%94%E2%80%94%E4%BD%A0%E8%81%BD%E9%81%8E%E9%98%BF%E9%87%8C%E5%B7%B4%E5%B7%B4%201688%20%E5%97%8E%3F",
      },
      {
        path: "blogPage/37759546",
        redirect:
          "page/blogPage/37759546-%E9%98%BF%E9%87%8C%E5%B7%B4%E5%B7%B4%201688%20%EF%BD%9C%E6%89%BE%E3%80%8C%E4%BB%A3%E8%B2%B7%E5%B9%AB%E3%80%8D%E7%9C%81%E6%99%82%E3%80%81%E6%96%B9%E4%BE%BF%E5%8F%88%E5%AE%89%E5%85%A8%E3%80%82",
      },
      {
        path: "blogPage/37760101",
        redirect:
          "page/blogPage/37760101-%E9%98%BF%E9%87%8C%E5%B7%B4%E5%B7%B4%201688%20%E5%A6%82%E4%BD%95%E6%89%B9%E8%B2%A8%EF%BC%9F%204%20%E5%A4%A7%E6%89%B9%E8%B2%A8%E6%8A%80%E5%B7%A7%E5%85%AC%E9%96%8B",
      },
      {
        path: "blogPage/37760893",
        redirect:
          "page/blogPage/37760893-1688%20%E6%89%B9%E8%B2%A8%E7%A7%98%E8%A8%A3%EF%BD%9C%E6%83%B3%E7%95%B6%E5%9C%98%E8%B3%BC%E4%B8%BB%EF%BC%8C%E8%A6%81%E5%A6%82%E4%BD%95%E8%91%97%E6%89%8B%EF%BC%9F",
      },
      {
        path: "blogPage/37761019",
        redirect:
          "page/blogPage/37761019-%E9%98%BF%E9%87%8C%E5%B7%B4%E5%B7%B4%E4%BB%A3%E8%B2%B7%EF%BD%9C%E7%82%BA%E4%BB%80%E9%BA%BC%E8%A6%81%E4%BD%BF%E7%94%A8%E6%98%93%E7%AB%8B%E5%A7%94%EF%BC%88EZ%20WAY%EF%BC%89%EF%BC%9F",
      },
      {
        path: "blogPage/37761925",
        redirect:
          "page/blogPage/37761925-%E9%98%BF%E9%87%8C%E5%B7%B4%E5%B7%B4%E4%BB%A3%E8%B2%B7%EF%BD%9C%E3%80%8C%E4%BB%A3%E8%B3%BC%E5%A7%94%E8%A8%97%E5%96%AE%E3%80%8D%E5%B8%B8%E8%A6%8B%E5%95%8F%E9%A1%8C",
      },
      {
        path: "blogPage/37762087",
        redirect:
          "page/blogPage/37762087-%E4%BB%A3%E8%B2%B7%E5%B9%AB%E8%A9%95%E5%83%B9%E5%9B%9E%E9%A5%8B%E3%80%82%E9%98%BF%E9%87%8C%E5%B7%B4%E5%B7%B4%E4%BB%A3%E8%B2%B7%E5%BF%AB%E9%80%9F%E5%8F%88%E6%96%B9%E4%BE%BF",
      },
      {
        path: "blogPage/38104021",
        redirect:
          "page/blogPage/381040211688%20%E6%89%B9%E7%99%BC%EF%BD%9C%E3%80%8C%E4%BB%A3%E8%B2%B7%E5%B9%AB%E3%80%8D%E5%A7%94%E8%A8%97%E5%96%AE%E5%90%88%E4%BD%B5%E6%95%99%E5%AD%B8",
      },
      {
        path: "blogPage/39320224",
        redirect:
          "page/blogPage/39320224-%E6%89%B9%E8%B2%A8%E6%95%99%E5%AD%B8%EF%BD%9C1688%20%E6%89%B9%E8%B2%A8%E7%9C%9F%E7%9A%84%E6%AF%94%E8%BC%83%E5%A5%BD%E5%97%8E%EF%BC%9F%E6%80%8E%E9%BA%BC%E6%89%B9%E6%9C%80%E5%88%92%E7%AE%97%EF%BC%9F",
      },
      {
        path: "blogPage/40077706",
        redirect:
          "page/blogPage/40077706-1688%20%E6%89%B9%E8%B2%A8%EF%BD%9C%E6%89%BE%E4%BB%A3%E4%BB%98%E9%82%84%E6%98%AF%E4%BB%98%E8%B2%B7%EF%BC%9F%E8%83%8C%E5%BE%8C%E7%9A%84%E9%9A%B1%E8%97%8F%E5%8D%B1%E6%A9%9F%EF%BC%8C%E6%98%AF%E5%90%A6%E8%A7%B8%E6%B3%95%EF%BC%9F",
      },
      {
        path: "blogPage/40926559",
        redirect:
          "page/blogPage/40926559-1688%20%E6%89%B9%E8%B2%A8%EF%BD%9C%E6%80%8E%E9%BA%BC%E6%A8%A3%E7%9A%84%E8%B3%BC%E8%B2%B7%E6%96%B9%E5%BC%8F%EF%BC%8C%E6%9C%80%E9%81%A9%E5%90%88%E6%88%91%EF%BC%9F",
      },
      {
        path: "blogPage/41667376",
        redirect:
          "page/blogPage/41667376-1688%20%E6%89%B9%E8%B2%A8%EF%BD%9C%E6%83%B3%E5%89%B5%E6%A5%AD%E3%80%81%E6%83%B3%E7%B6%93%E7%87%9F%E9%9B%BB%E5%95%86%E5%97%8E%EF%BC%9F%E8%B2%A8%E6%BA%90%E6%80%8E%E9%BA%BC%E9%81%B8%EF%9F%BC",
      },
      {
        path: "blogPage/42219838",
        redirect:
          "page/blogPage/42219838-%E6%89%B9%E8%B2%A8%E6%95%99%E5%AD%B8%EF%BD%9C1688%20%E6%89%B9%E8%B2%A8%EF%BC%8C%E6%88%90%E6%9C%AC%E8%A9%B2%E6%B3%A8%E6%84%8F%E4%BB%80%E9%BA%BC%EF%BC%9F",
      },
      {
        path: "blogPage/61246594",
        redirect:
          "page/blogPage/61246594-%E4%BB%A3%E8%B2%B7%E5%B9%AB%EF%BD%9C%E3%80%8C%E5%BF%AB%E9%80%9F%E6%89%B9%E8%B2%A8%E8%BC%94%E5%8A%A9%E5%B7%A5%E5%85%B7%E3%80%8D%E4%BD%BF%E7%94%A8%E8%AA%AA%E6%98%8E",
      },
      {
        path: "blogPage/61247377",
        redirect:
          "page/blogPage/61247377-%E6%B5%B7%E5%A4%96%E8%B3%BC%E7%89%A9%EF%BD%9C%E5%95%86%E6%A5%AD%E5%A0%B1%E9%97%9C%E6%98%AF%E4%BB%80%E9%BA%BC%EF%BC%9F",
      },
      {
        path: "blogPage/61248004",
        redirect:
          "page/blogPage/61248004-%E4%BB%A3%E8%B2%B7%E5%B9%AB%EF%BD%9C1688%20%E4%BB%A3%E8%B3%BC%EF%BC%8C%E5%87%BA%E8%B2%A8%E6%B5%81%E7%A8%8B%E8%AA%AA%E6%98%8E",
      },
      {
        path: "blogPage/61249603",
        redirect:
          "page/blogPage/61249603-%E4%BB%A3%E8%B2%B7%E5%B9%AB%EF%BD%9C%E6%9C%83%E5%93%A1%E5%88%B6%E5%BA%A6%E4%BB%8B%E7%B4%B9",
      },
      {
        path: "blogPage/61250446",
        redirect:
          "page/blogPage/61250446-%E5%9C%8B%E9%9A%9B%E9%81%8B%E8%B2%BB%E4%B8%8A%E6%BC%B2%EF%BC%8C%E5%A6%82%E4%BD%95%E5%BD%B1%E9%9F%BF%E9%81%8B%E8%BC%B8%E6%88%90%E6%9C%AC%E8%88%87%E9%81%8B%E8%BC%B8%E6%99%82%E6%95%88%EF%BC%9F",
      },
      {
        path: "blogPage/61477984",
        redirect:
          "page/blogPage/61477984-%E5%85%A8%E5%B9%B4%E5%BA%A6%E6%8E%A8%E8%96%A6%E8%A1%8C%E9%8A%B7%E9%87%8D%E9%BB%9E",
      },
      {
        path: "blogPage/61478356",
        redirect:
          "page/blogPage/61478356-%E4%BB%A3%E8%B2%B7%E5%B9%AB%EF%BD%9C%E4%BB%A3%E8%B3%BC%E5%B8%B8%E8%A6%8B%E5%95%8F%E9%A1%8C%EF%BC%88%E4%B8%8A%EF%BC%89",
      },
      {
        path: "blogPage/61478623",
        redirect:
          "page/blogPage/61478623-%E4%BB%A3%E8%B2%B7%E5%B9%AB%EF%BD%9C%E4%BB%A3%E8%B3%BC%E5%B8%B8%E8%A6%8B%E5%95%8F%E9%A1%8C%EF%BC%88%E4%B8%8B%EF%BC%89",
      },
      {
        path: "blogPage/61478974",
        redirect:
          "page/blogPage/61478974-%E6%B5%B7%E5%A4%96%E4%BB%A3%E8%B3%BC%EF%BD%9CEZWay%20%E6%96%B0%E8%A6%8F%E3%80%8C%E9%A0%90%E5%85%88%E5%A7%94%E4%BB%BB%E3%80%8D%EF%BC%8C%E8%A6%81%E6%B3%A8%E6%84%8F%E4%BB%80%E9%BA%BC%E5%97%8E%EF%BC%9F",
      },
      {
        path: "blogPage/61479190",
        redirect:
          "page/blogPage/61479190-%E6%89%B9%E8%B2%A8%E6%95%99%E5%AD%B8%EF%BD%9C1688%20%E9%98%BF%E9%87%8C%E5%B7%B4%E5%B7%B4%EF%BC%8C%E5%A6%82%E4%BD%95%E6%8C%91%E9%81%B8%E5%BB%A0%E5%95%86%E8%88%87%E5%95%86%E5%93%81%EF%BC%9F",
      },
      {
        path: "blogPage/61479400",
        redirect:
          "page/blogPage/61479400-%E3%80%90%E9%9B%BB%E5%95%86%E6%97%A5%E8%A8%98%E3%80%91%E5%89%B5%E7%AB%8B%E7%B6%B2%E8%B7%AF%E5%95%86%E5%BA%97%E7%9A%84%E7%AC%AC%E4%B8%80%E6%AD%A5%20EP.01",
      },
      {
        path: "blogPage/63090145",
        redirect:
          "page/blogPage/63090145-%E3%80%90%E9%9B%BB%E5%95%86%E6%97%A5%E8%A8%98%E3%80%91%E8%9D%A6%E7%9A%AE%E8%B3%A3%E5%A0%B4%E5%BB%BA%E7%BD%AE%20EP.02",
      },
      {
        path: "blogPage/64423372",
        redirect:
          "page/blogPage/64423372-%E3%80%90%E9%9B%BB%E5%95%86%E6%97%A5%E8%A8%98%E3%80%91%E5%A6%82%E4%BD%95%E6%8F%90%E9%AB%98%E8%B3%A3%E5%A0%B4%E6%9B%9D%E5%85%89%E5%BA%A6%EF%BC%9F%20EP.03",
      },
      {
        path: "blogPage/65674783",
        redirect:
          "page/blogPage/65674783-%E3%80%90%E9%9B%BB%E5%95%86%E6%97%A5%E8%A8%98%E3%80%91%E5%A6%82%E4%BD%95%E5%88%86%E6%9E%90%E5%BB%A3%E5%91%8A%E6%88%90%E6%95%88%EF%BC%9FEP.04",
      },
      {
        path: "blogPage/66253645",
        redirect:
          "page/blogPage/66253645-%E4%BB%A3%E8%B2%B7%E5%B9%AB%EF%BD%9C1688%20%E5%90%88%E4%BD%B5%E8%A8%82%E5%96%AE%E8%AA%AA%E6%98%8E",
      },
      {
        path: "blogPage/68118106",
        redirect:
          "page/blogPage/68118106-%E6%89%B9%E8%B2%A8%E6%95%99%E5%AD%B8%EF%BD%9C1688%20%E5%A6%82%E4%BD%95%E9%80%80%E6%8F%9B%E8%B2%A8%20&%20%E9%80%80%E6%AC%BE%E6%B5%81%E7%A8%8B",
      },
      {
        path: "blogPage/69436093",
        redirect:
          "page/blogPage/69436093-%E3%80%90%E9%9B%BB%E5%95%86%E6%97%A5%E8%A8%98%E3%80%91%E8%9D%A6%E7%9A%AE%E8%B3%A3%E5%AE%B6%E6%8A%80%E5%B7%A7%E6%95%B4%E7%90%86%20EP.05",
      },
      {
        path: "blogPage/72199219",
        redirect:
          "page/blogPage/72199219-%E6%B5%B7%E9%97%9C%E8%A6%8F%E7%AF%84%EF%BD%9C%E7%A6%81%E9%81%8B%E5%95%86%E5%93%81%E6%95%B4%E7%90%86",
      },
      {
        path: "blogPage/74174866",
        redirect:
          "page/blogPage/74174866-%E6%B5%B7%E5%A4%96%E8%B3%BC%E7%89%A9%EF%BD%9CEZ%20Way%20%E6%98%93%E5%88%A9%E5%A7%94%EF%BC%8C%E6%96%B0%E5%A2%9E%E3%80%8C%E5%81%A5%E4%BF%9D%E5%8D%A1%E3%80%8D%E8%BA%AB%E5%88%86%E9%A9%97%E8%AD%89",
      },
      {
        path: "blogPage/77465626",
        redirect:
          "page/blogPage/77465626-%E6%B5%B7%E5%A4%96%E4%BB%A3%E8%B3%BC%EF%BD%9C%E3%80%8C%E9%99%90%E5%88%B6%E5%9E%8B%E3%80%8D%E9%80%B2%E5%8F%A3%E5%95%86%E5%93%81%E6%9C%89%E5%93%AA%E4%BA%9B%EF%BC%9F",
      },
      {
        path: "blogPage/81263644",
        redirect:
          "page/blogPage/81263644-2023%E5%85%A8%E5%B9%B4%E5%BA%A6%E6%8E%A8%E8%96%A6%E8%A1%8C%E9%8A%B7%E9%87%8D%E9%BB%9E",
      },
      {
        path: "blogPage/86106958",
        redirect:
          "page/blogPage/86106958-%E6%B5%B7%E5%A4%96%E8%B3%BC%E7%89%A9%EF%BD%9CEZWAY%20%E6%98%93%E5%88%A9%E5%A7%94%E3%80%8C%E9%A0%90%E5%85%88%E5%A7%94%E4%BB%BB%E3%80%8D%E5%8A%9F%E8%83%BD%20%E8%AA%AA%E6%98%8E",
      },
      {
        path: "blogPage/152852254",
        redirect:
          "page/blogPage/152852254-%E4%BB%A3%E8%B2%B7%E5%B9%AB%E7%B6%B2%E7%AB%99%E5%85%A8%E6%96%B0%E6%94%B9%E7%89%88",
      },
      {
        path: "blogPage/164045008",
        redirect:
          "page/blogPage/164045008-%E9%9C%B2%E7%87%9F%E6%96%B0%E6%89%8B%E6%8C%87%E5%8D%97%EF%BC%9A%E7%A7%8B%E5%86%AC%E9%9C%B2%E7%87%9F%E8%A3%9D%E5%82%99%E5%85%A8%E6%94%BB%E7%95%A5%EF%BC%8C%E5%BE%9E%E9%9B%B6%E9%96%8B%E5%A7%8B%E8%BC%95%E9%AC%86%E6%BA%96%E5%82%99",
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
